//import axios from "../utils/axios";
import Axios from "axios";

function buscarPessoaUsuarioLogado() {
  return new Promise((resolve, reject) => {
    Axios.get(`/pessoaUsuario/usuarioLogado`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscarPorId(id) {
  return new Promise((resolve, reject) => {
    Axios
      .get(`/pessoaUsuario/buscarPorId/${id}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updatePessoaUsuarioLogado({
  email,
  telefone,
  nome,
  dataNascimento,
  bibliografia,
}) {
  return new Promise((resolve, reject) => {
    const vo = {
      email,
      telefone,
      nome,
      dataNascimento,
      bibliografia,
    };
    Axios.put(`/pessoaUsuario/usuarioLogado`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscarPorDocumento(cpf) {
  return new Promise((resolve, reject) => {
    Axios.get(`/pessoaUsuario/buscarPorDocumento/${cpf}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function save({ idPessoaUsuario, profissao, cargo, documento, email, telefone, sexo, nome, estadoCivil, dataNascimento }) {
  return new Promise((resolve, reject) => {

    var vo = {
      idPessoaUsuario, profissao, cargo, documento, email, telefone, sexo, nome, estadoCivil, dataNascimento
    }

    Axios
      .post(`/pessoaUsuario/`, vo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function pesquisar(documento, email, nome, razaoSocial, status, page = 0) {
  return new Promise((resolve, reject) => {
    var vo = {
      documento: documento,
      email: email,
      nome: nome,
      razaoSocial: razaoSocial,
      usuario: {
        status
      }
    };

    Axios.post(`/pessoaUsuario/pesquisa/${page}`, vo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


function importarViaArquivo(file) {
  const formData = new FormData();
  formData.append("file", file);
  return Axios.post("/pessoaUsuario/importarViaArquivo", formData, {
    headers: { "content-type": "multipart/form-data;" },
  });
}


const PessoaUsuarioService = {
  buscarPessoaUsuarioLogado,
  updatePessoaUsuarioLogado,
  buscarPorDocumento,
  pesquisar,
  importarViaArquivo,
  buscarPorId,
  save
};

export default PessoaUsuarioService;
